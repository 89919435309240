<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("activated_vehicles") }}</h1>
    <br>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="vehicles">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div v-if="role ==='admin' || role === 'managerSubcontractor'" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
          </div>

        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ vehicles.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : vehicles.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

    <template slot="thead">
      <vs-th sort-key="name">{{ $t("vehicle") }}</vs-th>
      <vs-th sort-key="type">{{ $t("type") }}</vs-th>
      <vs-th sort-key="plateNumber"> {{ $t("num_plaque") }}</vs-th>
      <vs-th sort-key="greyCard"> {{ $t("carte_grise") }}</vs-th>
      <vs-th sort-key="model">{{ $t("model") }}</vs-th>
      <vs-th sort-key="company">{{ $t("company") }}</vs-th>
    <!--  <vs-th sort-key="frontAirbag">Dernière visite technique</vs-th>
      <vs-th sort-key="greyCard">Date d'expiration assurance</vs-th> -->
      <vs-th sort-key="greyCard">{{ $t("nokiaValidation") }}</vs-th>
      <vs-th>Action</vs-th>
    </template>

    <template slot-scope="{data}">
      <tbody>
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td>
          <p class="product-name font-medium truncate">{{ tr.name}}</p>
        </vs-td>
        <vs-td>
          <p v-if="getlang ==='fr'"  class="product-name font-medium truncate">{{voiture_Object[tr.type]}}</p>
          <p v-if="getlang ==='en'"  class="product-name font-medium truncate">{{voiture_Object_en[tr.type]}}</p>
        </vs-td>
        <vs-td>
          <p class="product-name font-medium truncate">{{tr.plateNumber}}</p>
        </vs-td>
        <vs-td>
          <p class="product-name font-medium truncate">{{tr.greyCard}}</p>
        </vs-td>
        <vs-td>
          <p class="product-name font-medium truncate">{{tr.model}}</p>
        </vs-td>
        <vs-td>
              <p class="product-name font-medium truncate">{{ tr.company !== null ? tr.company.name : '-'}}</p>
        </vs-td>
      <!--  <vs-td>
          <p class="product-name font-medium truncate">{{tr.lastTechnicalVisitDate}}</p>
        </vs-td>
        <vs-td>
          <p class="product-name font-medium truncate">{{tr.expiryDate}}</p>
        </vs-td> -->
        <vs-td>
          <vs-chip  v-if="getlang ==='fr'" :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject[tr.activated]}}</vs-chip>
          <vs-chip  v-if="getlang ==='en'" :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject_en[tr.activated]}}</vs-chip>
        </vs-td>
      
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vx-tooltip  :text="$t('image')" color="#28C76F">
              <feather-icon style="color: #14078f " icon="ImageIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"   @click="openLightbox" @click.stop="Show_image(tr)" color="warring" type="border" class="mr-2" />
            </vx-tooltip>
            <vx-tooltip  :text="$t('Voir')" color="#28C76F">
              <feather-icon style="color: #06ac0e " icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current"   @click.stop="ShowData(tr)" color="warring" type="border" class="mr-2" />
            </vx-tooltip>
            <vx-tooltip v-if="role !== 'nokiaProject' && role !== 'qhsNokia' && role !== 'teamLeaderSubcontractor' && role !== 'pessNokia'" :text="$t('Modifier')" color="warning">
              <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>
            </vx-tooltip>
            <vx-tooltip v-if="role !== 'nokiaProject' && role !== 'qhsNokia' && role !== 'teamLeaderSubcontractor' && role !== 'pessNokia'" :text="$t('Supprimer')" color="danger">
              <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
            </vx-tooltip>
            <vx-tooltip v-if="role !=='managerSubcontractor'" :text="$t('valider_véhicule')" color="success">
              <feather-icon @click="comfirmation(tr.id)" style="color: #06ac0e" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="mr-2"/>
            </vx-tooltip>
            <vx-tooltip v-if="role !=='managerSubcontractor'" :text="$t('rejeter_véhicule')" color="danger">
              <feather-icon @click="rejet_Pop(tr.id)" style="color: #ff6141" icon="XCircleIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
            </vx-tooltip>
            <vx-tooltip :text="$t('rapport')" color="dark">
              <feather-icon v-if="tr.activated === 0 && role !=='managerSubcontractor'  " @click="rapport(tr.vehicleComments)" style="color: black" icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-dark stroke-current" class="mr-2"/>
            </vx-tooltip>
          </div>
        </vs-td>
      </vs-tr>
      </tbody>
      <vs-popup :title="$t('images_vehicle')" :active.sync="showLightbox" class="items-no-padding">
      <div id="my-strictly-unique-vue-image-lightbox-carousel" style="text-align: center;">
        <vue-image-lightbox-carousel
          ref="lightbox"
          :show="showLightbox"
          @close="showLightbox = false"
          :images="images"
          @change="changeImage"
        >
        </vue-image-lightbox-carousel>
      </div>
      </vs-popup>
    </template>
    </vs-table>

    <vs-popup
    v-if="activePrompt2" 
      fullscreen 
      class="calendar-event-dialog"
      :title="$t('ajouter_vehicle')"
      :active.sync="activePrompt2">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>{{ $t("vehicule") }}<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("vehicle_error") }}</span>
  
           <p v-if="getlang ==='fr'"> {{ $t("type") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
              v-if="getlang ==='fr'"
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='fr'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
          
          <p v-if="getlang ==='en'"> {{ $t("type") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
              v-if="getlang ==='en'"
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='en'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises_en" /></vs-select>
          
          
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ $t("type_error") }}</span>
           
            <p>{{ $t("num_plaque") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('plateNumber')">{{ $t("plateNumber_error") }}</span>
            
            <p>{{ $t("carte_grise") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('greyCard')">{{ $t("greyCard_error") }}</span>
  
          
            <p>{{ $t("Nombre_ceinture_sécurité_02") }}<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ $t("numberOf02PointsSeatBelt_error") }}</span>
            
            <p>{{ $t("Nombre_ceinture_sécurité_03") }}<b style="color: #ff6141">*</b></p>

            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
              data-vv-validate-on="blur"
              name="numberOf03PointsSeatBelt"
              v-model="numberOf03PointsSeatBelt"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('numberOf03PointsSeatBelt')">{{ $t("numberOf03PointsSeatBelt_error") }}</span>
            
            <p>{{ $t("air_bague") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('frontAirbag')">{{ $t("frontAirbag_error") }}</span>
            
            <p>{{ $t("rental") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="rental"
            autocomplete
            name="rental"
            class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('rental')">{{ $t("rental_error") }}</span>

            <p v-if="rental" >{{ $t("rentalCompany") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="rental" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rentalCompany"
              v-model="rentalCompany"
              class="w-full"
            />
            <span v-if="rental" class="text-danger text-sm" v-show="errors.has('rentalCompany')">{{ $t("rentalCompany_error") }}</span>
  
            <p v-if="rental"  >{{ $t("rentalCompanyContratReference") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="rental" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rentalCompanyContratReference"
              v-model="rentalCompanyContratReference"
              class="w-full"
            />
            <span v-if="rental" class="text-danger text-sm" v-show="errors.has('rentalCompanyContratReference')">{{ $t("rentalCompanyContratReference") }}</span>
  
         
        </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            <p v-if="role !== 'managerSubcontractor'" >{{ $t("company") }}<b style="color: #ff6141">*</b></p>
            <v-select
                v-if="role !== 'managerSubcontractor'"
                v-validate="'required'"
                name="company"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="company"
                :options="companies">
              </v-select>
             <span v-if="role !== 'managerSubcontractor'" class="text-danger text-sm" v-show="errors.has('company')">{{ $t("company_error") }}</span>
            
            <p>{{ $t("date_mise_circulation") }} <b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              v-validate="'required'"
              name="firtsReleaseDate"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('firtsReleaseDate')">{{ $t("firtsReleaseDate_error") }}</span>
    
            <p>{{ $t("compagnie_assurance") }}<b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('insuranceCompany')">{{ $t("insuranceCompany_error") }}</span>
            
            <p>{{ $t("type_assurance") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />
            <span class="text-danger text-sm" v-show="errors.has('typeOfInsuranceContract')">{{ $t("typeOfInsuranceContract_error") }}</span>
            
            
            <p>{{ $t("num_assurance") }} <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('insurancePolicyNumber')">{{ $t("insurancePolicyNumber_error") }}</span>
            
            
            <p>{{ $t("date_expiration") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            v-validate="'required'"
            name="expiryDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('expiryDate')">{{ $t("expiryDate_error") }}</span>
            
            <p>{{ $t("dernière_visite_technique") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            v-validate="'required'"
            name="lastTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('lastTechnicalVisitDate')">{{ $t("lastTechnicalVisitDate_error") }}</span>
           
            <p>{{ $t("date_visite_technique_venir") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            v-validate="'required'"
            name="nextTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('nextTechnicalVisitDate')">{{ $t("nextTechnicalVisitDate_error") }}</span>
  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            
              
            <p>{{ $t("date_maitenance_venir") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            v-validate="'required'"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('dateOfTheNextMaintenanceVisit')">{{ $t("dateOfTheNextMaintenanceVisit_error") }}</span>
  
             <p> {{ $t("suivi_ivms") }} <b style="color: #ff6141" >*</b> </p>
             <vs-checkbox 
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('ivmsTracker')">{{ $t("ivmsTracker_error") }}</span>
    
            <p>{{ $t("modele") }} <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="model"
            v-model="model"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('model')">{{ $t("model_error") }}</span>
         
            <p>{{ $t("Kilomètrage") }} <b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('killometerOnTheDashboard')">{{ $t("killometerOnTheDashboard_error") }}</span>
         
            <p>{{ $t("rapport_ivms") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
             
          <span class="text-danger text-sm" v-show="errors.has('ivmsReport')">{{ $t("ivmsReport_error") }}</span>
            
          <p>{{ $t("carnet_entretien") }}<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('maintenanceNotebook')">{{ $t("maintenanceNotebook_error") }}</span>
           
            <p class="pt-4">{{ $t("image") }}<b style="color: #ff6141" ></b> </p>
          <vs-button color="success" icon-pack="feather" icon="icon-image" @click="Vehicule_Image=true">{{ $t("choisir") }}</vs-button>
          <vs-popup title="Image(s)" :active.sync="Vehicule_Image" class="items-no-padding">
            <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="image/*">
            <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2" multiple accept="image/*">
            <div class="carousel-example">
              <swiper  :options="swiperOption" style="height: 300px !important;">
                <swiper-slide  v-for="(data, index) in dataImg1" :key="index + '458'">
                  <vs-button
                    icon-pack="feather" icon="icon-trash" size="small" color="danger" @click="Delete_Image({id: data.id,key:index})">{{ $t("Supprimer") }}
                  </vs-button>
                  <br>
                  <img  style="height: 300px !important;" class="responsive" :src= " data.url != null ? $store.state.image_url+data.url : data" alt="banner">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <br>
            <div v-if="this.update_Image === true " class="flex flex-wrap justify-between mb-3">
            <vs-button  icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput2.click()">{{ $t("Ajouter") }}</vs-button>
            <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">{{ $t("Valider") }}</vs-button>
            </div>
            <div v-if="this.update_Image === false " class="flex flex-wrap justify-between mb-3">
              <vs-button icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput1.click()">{{ $t("Ajout_Nouvel") }}</vs-button>
              <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">{{ $t("Soumettre") }}</vs-button>
            </div>
          </vs-popup>
          
            
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Soumettre") }}</vs-button>
     </div>
    </vs-popup>
    
    <vs-popup
    v-if="activePrompt1" 
      fullscreen 
      class="calendar-event-dialog"
      :title="$t('modifier_vehicle')"
      :active.sync="activePrompt1">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>{{ $t("vehicule") }}<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("vehicle_error") }}</span>
  
           <p v-if="getlang ==='fr'"> {{ $t("type") }} <b style="color: #ff6141" >*</b> </p>
              <vs-select
              v-if="getlang ==='fr'"
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='fr'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
          
          <p v-if="getlang ==='en'"> {{ $t("type") }} <b style="color: #ff6141" >*</b> </p>
              <vs-select
              v-if="getlang ==='en'"
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='en'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises_en" /></vs-select>
          
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ $t("type") }}</span>
           
            <p>{{ $t("num_plaque") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('plateNumber')">{{ $t("plateNumber") }}</span>
            
            <p>{{ $t("carte_grise") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('greyCard')">{{ $t("greyCard") }}</span>
  
          
            <p>{{ $t("Nombre_ceinture_sécurité_02") }}<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ $t("numberOf02PointsSeatBelt") }}</span>
            
            <p>{{ $t("Nombre_ceinture_sécurité_03") }}<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
              data-vv-validate-on="blur"
              name="numberOf03PointsSeatBelt"
              v-model="numberOf03PointsSeatBelt"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('numberOf03PointsSeatBelt')">{{ $t("numberOf03PointsSeatBelt") }}</span>
            <p> {{ $t("air_bague") }} <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('frontAirbag')">{{ $t("frontAirbag") }}</span>
          
          <p>{{ $t("rental") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="rental"
            autocomplete
            name="rental"
            class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('rental')">{{ $t("rental_error") }}</span>

            <p v-if="rental" >{{ $t("rentalCompany") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="rental" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rentalCompany"
              v-model="rentalCompany"
              class="w-full"
            />
            <span v-if="rental" class="text-danger text-sm" v-show="errors.has('rentalCompany')">{{ $t("rentalCompany_error") }}</span>
  
            <p v-if="rental"  >{{ $t("rentalCompanyContratReference") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="rental" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rentalCompanyContratReference"
              v-model="rentalCompanyContratReference"
              class="w-full"
            />
            <span v-if="rental" class="text-danger text-sm" v-show="errors.has('rentalCompanyContratReference')">{{ $t("rentalCompanyContratReference") }}</span>
        
        </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            <p v-if="role !== 'managerSubcontractor'" >{{ $t("company") }}<b style="color: #ff6141">*</b></p>
            <v-select
                v-if="role !== 'managerSubcontractor'"
                v-validate="'required'"
                name="company"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="company"
                :options="companies">
              </v-select>
             <span v-if="role !== 'managerSubcontractor'" class="text-danger text-sm" v-show="errors.has('company')">{{ $t("company_error") }}</span>

            <p>{{ $t("date_mise_circulation") }} <b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              v-validate="'required'"
              name="firtsReleaseDate"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('firtsReleaseDate')">{{ $t("firtsReleaseDate") }}</span>
    
            <p>{{ $t("compagnie_assurance") }}<b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('insuranceCompany')">{{ $t("insuranceCompany") }}</span>
            
            <p>{{ $t("type_assurance") }}<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />
            <span class="text-danger text-sm" v-show="errors.has('typeOfInsuranceContract')">{{ $t("typeOfInsuranceContract") }}</span>
            
            
            <p>{{ $t("num_assurance") }} <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('insurancePolicyNumber')">{{ $t("insurancePolicyNumber") }}</span>
            
            
            <p>{{ $t("date_expiration") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            v-validate="'required'"
            name="expiryDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('expiryDate')">{{ $t("expiryDate") }}</span>
            
            <p>{{ $t("dernière_visite_technique") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            v-validate="'required'"
            name="lastTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('lastTechnicalVisitDate')">{{ $t("lastTechnicalVisitDate") }}</span>
           
            <p>{{ $t("date_visite_technique_venir") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            v-validate="'required'"
            name="nextTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('nextTechnicalVisitDate')">{{ $t("nextTechnicalVisitDate") }}</span>
  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            
              
            <p>{{ $t("date_maitenance_venir") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            v-validate="'required'"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('dateOfTheNextMaintenanceVisit')">{{ $t("dateOfTheNextMaintenanceVisit") }}</span>
  
             <p>{{ $t("suivi_ivms") }} <b style="color: #ff6141" >*</b> </p>
             <vs-checkbox 
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
             
            <span class="text-danger text-sm" v-show="errors.has('ivmsTracker')">{{ $t("ivmsTracker") }}</span>
    
            <p>{{ $t("modele") }} <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="model"
            v-model="model"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('model')">{{ $t("model") }}</span>
         
            <p>{{ $t("Kilomètrage") }}  <b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('killometerOnTheDashboard')">{{ $t("killometerOnTheDashboard") }}</span>
         
            <p>{{ $t("rapport_ivms") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('ivmsReport')">{{ $t("ivmsReport") }}</span>
            
          <p>{{ $t("carnet_entretien") }}<b style="color: #ff6141">*</b></p>
          <vs-checkbox 
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
        
            <span class="text-danger text-sm" v-show="errors.has('maintenanceNotebook')">{{ $t("maintenanceNotebook") }}</span>
           
            <p class="pt-4">{{ $t("image") }}<b style="color: #ff6141" ></b> </p>
          <vs-button color="success" icon-pack="feather" icon="icon-image" @click="Vehicule_Image=true">{{ $t("choisir") }}</vs-button>
          <vs-popup title="Image(s)" :active.sync="Vehicule_Image" class="items-no-padding">
            <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="image/*">
            <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2" multiple accept="image/*">
            <div class="carousel-example">
              <swiper  :options="swiperOption" style="height: 300px !important;">
                <swiper-slide  v-for="(data, index) in dataImg1" :key="index + '458'">
                  <vs-button
                    icon-pack="feather" icon="icon-trash" size="small" color="danger" @click="Delete_Image({id: data.id,key:index})">{{ $t("Supprimer") }}
                  </vs-button>
                  <br>
                  <img  style="height: 300px !important;" class="responsive" :src= " data.url != null ? $store.state.image_url+data.url : data" alt="banner">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <br>
            <div v-if="this.update_Image === true " class="flex flex-wrap justify-between mb-3">
            <vs-button  icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput2.click()">{{ $t("Ajouter") }}</vs-button>
            <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">{{ $t("Valider") }}</vs-button>
            </div>
            <div v-if="this.update_Image === false " class="flex flex-wrap justify-between mb-3">
              <vs-button icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput1.click()">{{ $t("Ajout_Nouvel") }}</vs-button>
              <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">{{ $t("Valider") }}</vs-button>
            </div>
          </vs-popup>
          
            
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Soumettre") }}</vs-button>
     </div>
    </vs-popup>

    <vs-popup
    v-if="activePrompt3" 
      fullscreen 
      class="calendar-event-dialog"
     :title="$t('details_vehicle')"
      :active.sync="activePrompt3">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>{{ $t("vehicule") }}<b style="color: #ff6141"></b></p>
          <vs-input
            disabled
            name="name"
            v-model="name"
            class="w-full"
          />
  
           <p  v-if="getlang ==='fr'"> {{ $t("type") }} <b style="color: #ff6141" ></b> </p>
              <vs-select
              v-if="getlang ==='fr'"
                disabled
                v-model="type"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='fr'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
          <p  v-if="getlang ==='en'"> {{ $t("type") }} <b style="color: #ff6141" ></b> </p>           
          <vs-select
              v-if="getlang ==='en'"
                disabled
                v-model="type"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item v-if="getlang ==='en'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises_en" /></vs-select>
                     
            <p>{{ $t("num_plaque") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            
            <p>{{ $t("carte_grise") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />  
          
            <p>{{ $t("Nombre_ceinture_sécurité_02") }}<b style="color: #ff6141"></b></p>
            <vs-input-number
            disabled
            :min="0" 
            :max="120"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ $t("numberOf02PointsSeatBelt") }}</span>
            
            <p>{{ $t("Nombre_ceinture_sécurité_03") }}<b style="color: #ff6141"></b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            disabled
            name="numberOf03PointsSeatBelt"
            v-model="numberOf03PointsSeatBelt"
            class="w-full"
            />
            <p> {{ $t("air_bague") }} <b style="color: #ff6141" ></b> </p>
            <vs-checkbox 
            disabled
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
          
            <p>{{ $t("rental") }}<b style="color: #ff6141" ></b> </p>
            <vs-checkbox 
            disabled
            v-model="rental"
            autocomplete
            name="rental"
            class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('rental')">{{ $t("rental_error") }}</span>

            <p v-if="rental" >{{ $t("rentalCompany") }}<b style="color: #ff6141"></b></p>
            <vs-input
              v-if="rental"
              disabled
              data-vv-validate-on="blur"
              name="rentalCompany"
              v-model="rentalCompany"
              class="w-full"
            />
  
            <p v-if="rental">{{ $t("rentalCompanyContratReference") }}<b style="color: #ff6141"></b></p>
            <vs-input
              v-if="rental"
              disabled
              data-vv-validate-on="blur"
              name="rentalCompanyContratReference"
              v-model="rentalCompanyContratReference"
              class="w-full"
            />
            </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            <p v-if="role !== 'managerSubcontractor'">{{ $t("company") }}<b style="color: #ff6141"></b></p>
            <v-select
                disabled
                name="company"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="company"
                :options="companies">
              </v-select>
            
             <p>{{ $t("date_mise_circulation") }} <b style="color: #ff6141"></b></p>
            <flat-pickr 
              disabled
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              name="firtsReleaseDate"
              class="w-full" />
    
            <p>{{ $t("compagnie_assurance") }}<b style="color: #ff6141" ></b> </p>
              <v-select
                disabled
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            
            <p>{{ $t("type_assurance") }}<b style="color: #ff6141"></b></p>
            <vs-input
             disabled
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />            
            
            <p>{{ $t("num_assurance") }} <b style="color: #ff6141"></b></p>
            <vs-input
            disabled
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />            
            
            <p>{{ $t("date_expiration") }}<b style="color: #ff6141"></b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            name="expiryDate"
            class="w-full" />
            
            <p>{{ $t("dernière_visite_technique") }}<b style="color: #ff6141"></b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            name="lastTechnicalVisitDate"
            class="w-full" />
           
            <p>{{ $t("date_visite_technique_venir") }}<b style="color: #ff6141"></b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            name="nextTechnicalVisitDate"
            class="w-full" />  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            <p>{{ $t("date_maitenance_venir") }}<b style="color: #ff6141"></b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
  
             <p>{{ $t("suivi_ivms") }} <b style="color: #ff6141" ></b> </p>
             <vs-checkbox 
                disabled
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
               
            <p>{{ $t("modele") }} <b style="color: #ff6141"></b></p>
            <vs-input
            disabled
            name="model"
            v-model="model"
            class="w-full"
            />
         
            <p>{{ $t("Kilomètrage") }}  <b style="color: #ff6141"></b></p>
            <vs-input-number
            disabled
            :min="0" 
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
         
            <p>{{ $t("rapport_ivms") }}<b style="color: #ff6141" ></b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                disabled
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
          <p>{{ $t("carnet_entretien") }}<b style="color: #ff6141"></b></p>
            <vs-checkbox 
                disabled
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
        
        </div>
      </div>
      <vs-button color="primary"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
     </div>
    </vs-popup>

    <vs-popup classContent="popup-example" :title="$t('ajouter_votre_commentaire')" :active.sync="activePrompt4">
    <vs-textarea
      v-model="comment"
      rows="5"
      class="w-full" >
    </vs-textarea>
    <div class="flex flex-wrap justify-between mt-5 ">
    <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_comfirmation">{{ $t("Annuler") }}</vs-button>
    <vs-button  :disabled="!validate_comment" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="rejete_comfirmation">{{ $t("Valider") }}</vs-button>
    </div>
    </vs-popup>

    <vs-popup classContent="popup-example" :title="$t('details_du_rejet')" :active.sync="activePrompt5">

    <li class="mb-2" v-for="comment in details" :key="comment" >{{ comment.comment }}</li>

    </vs-popup>
    </div>
</template>
<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import VueImageLightboxCarousel from './SliderComponent'
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr

const dict = {
  custom: {
    name: {
      required: 'Le champ nom  est obligatoire'
    },
    type: {
      required: 'Le champ type est obligatoire'
    }, 
    plateNumber: {
      required: 'Le champ numéro de plaque est obligatoire'
    },
    greyCard: {
      required: 'Le champ catre grise est obligatoire'
    },
    numberOf03PointsSeatBelt: {
      required: 'Le champ nombre de place 03 est obligatoire'
    },
    numberOf02PointsSeatBelt: {
      required: 'Le champ nombre de place 02 est obligatoire'
    },
    frontAirbag: {
      required: 'Le champ air bague est obligatoire'
    },
    firtsReleaseDate: {
      required: 'Le champ première date de sortie est obligatoire'
    },
    insuranceCompany: {
      required: 'Le champ compagnie est obligatoire'
    },
    insurancePolicyNumber: {
      required: 'Le champ numéro d\'assurance est obligatoire'
    },
    typeOfInsuranceContract: {
      required: 'Le champ type de contrat d\'assurance est obligatoire'
    },
    expiryDate: {
      required: 'Le champ date d\'expiration est obligatoire'
    }, 
    lastTechnicalVisitDate: {
      required: 'Le champ dernière visite est obligatoire'
    },
    nextTechnicalVisitDate: {
      required: 'Le champ date de visite technique à venir  est obligatoire'
    },
    ivmsReport: {
      required: 'Le champ rapport ivms est obligatoire'
    },
    dateOfTheNextMaintenanceVisit: {
      required: 'Le champ date de la visite suivante est obligatoire'
    },
    ivmsTracker: {
      required: 'Le champ suivie ivms est obligatoire'
    },
    model: {
      required: 'Le champ modèle est obligatoire'
    },
    killometerOnTheDashboard: {
      required: 'Le champ kilomètrage visite est obligatoire'
    },
    maintenanceNotebook: {
      required: 'Le champ carnet de maintenance est obligatoire'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
export default {
  components: {
    VueImageLightboxCarousel,
    swiper,
    swiperSlide,
    flatPickr
  },
  data () {
    return {
      role:'',
      id:'',
      rental:false,
      rentalCompany:'',
      rentalCompanyContratReference:'',
      company:'',
      companies:[],
      datafiles:[],
      indexfiles:[],
      dataImg: [],
      AddNewdataImg_file: [],
      update_Image:true,
      dataImg1: [],
      dataImg1_delete: [],
      dataImg1_file: [],
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      configdateTimePicker: {
        enableTime: 'false',
        dateFormat: 'Y-m-d',
        locale: French
      },
      Vehicule_Image:false,
      activePrompt5:false,
      activePrompt4:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      showLightbox: false,
      name:'',
      type:'',
      plateNumber:'',
      greyCard:'',
      numberOf03PointsSeatBelt:'',
      numberOf02PointsSeatBelt:'',
      frontAirbag:0,
      firtsReleaseDate:'',
      insuranceCompany:'',
      typeOfInsuranceContract:'',
      insurancePolicyNumber:'',
      expiryDate:'',
      lastTechnicalVisitDate:'',
      nextTechnicalVisitDate:'',
      dateOfTheNextMaintenanceVisit:'',
      ivmsTracker:0,
      model:'',
      ivmsReport:0,
      killometerOnTheDashboard:'',
      maintenanceNotebook:0,
      proofs:[],
      images: [],
      vehicles: [],
      selected: [],
      type_choises: [
        {
          key: 'truck',
          name: 'Camion'
        },
        {
          key: 'car',
          name: 'Voiture'
        },
        {
          key: 'bus',
          name: 'Bus'
        }
      ],
      type_choises_en: [
        {
          key: 'truck',
          name: 'Truck'
        },
        {
          key: 'car',
          name: 'Car'
        },
        {
          key: 'bus',
          name: 'Bus'
        }
      ],
      itemsPerPage: 20,
      isMounted: false,
      voiture_Object: {
        truck: 'Camion',
        car: 'Voiture',
        bus: 'Bus'
      },
      voiture_Object_en: {
        truck: 'Truck',
        car: 'Car',
        bus: 'Bus'
      },
      comment : '',
      vehicule_id : '',
      validationObject: {
        0: 'Rejetée',
        1: 'Encours',
        2: 'Validée'
      },
      validationObject_en: {
        0: 'Rejected',
        1: 'Verification',
        2: 'Valid'
      },
      details :[]
    }
  },
  watch:{
    rental (value) {
      if (value) {
        this.rental_check = 1
      } else {
        this.rental_check = 0
      }
    }
  },
  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    validate_comment () {
      if (
        (
          this.comment !== ''  
        )) return true
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.vehicles.length
    }
  },
 
  methods: {
    rapport (tr) {
      this.details = []
      this.activePrompt5 = true
      this.details = tr
    },
    rejete_comfirmation () {
      const input = {
        vehicle : this.vehicule_id,
        comment : this.comment
      }
      //const vehicle = this.vehicule_id
      //const comment = this.comment
      this.$vs.loading()
      this.$http.post('reject-vehicle/', input)
        .then(response => {
          this.getAllVehicles()
          window.getPrendTaCom.success(this.$t('rejete_vehicule'), response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message:this.$t('echec_rejet_vehicule') })
        })
      this.activePrompt4 = false
    },
    comfirmation (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: this.$t('comfirm_validation'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          const vehicle = id
          this.$vs.loading()
          this.$http.post('validate-vehicle/', {vehicle})
            .then(response => {
              this.getAllVehicles()
              window.getPrendTaCom.success(this.$t('valide_vehicule'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message:this.$t('echec_validation_vehicule')})
            })
        }
      })
    },

    resetData_comfirmation () {
      this.comment = ''
      this.vehicule_id = ''
      this.activePrompt4 = false
    },
    rejet_Pop (id) {
      this.vehicule_id = id
      this.comment = ''
      this.activePrompt4 = true
    },
    getOrderStatusColor (status) {
      if (status === 0) {
        return 'danger'
      } else if (status === 1) {
        return 'warning'
      } else if (status === 2) {
        return 'success'
      }
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.update_Image = false
      this.activePrompt2 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
     
    },
    ShowData (data) {
      this.intialise()
      this.id = data.id
      this.rental =  data.rental
      this.rentalCompany =  data.rentalCompany
      this.rentalCompanyContratReference =  data.rentalCompanyContratReference
      this.company = data.company.id
      this.name = data.name
      this.type = data.type
      this.plateNumber = data.plateNumber
      this.greyCard = data.greyCard
      this.numberOf03PointsSeatBelt = data.numberOf03PointsSeatBelt
      this.numberOf02PointsSeatBelt = data.numberOf02PointsSeatBelt
      this.frontAirbag = data.frontAirbag
      this.firtsReleaseDate = data.firtsReleaseDate
      this.insuranceCompany = data.insuranceCompany.id
      this.typeOfInsuranceContract = data.typeOfInsuranceContract
      this.insurancePolicyNumber = data.insurancePolicyNumber
      this.expiryDate = data.expiryDate
      this.lastTechnicalVisitDate = data.lastTechnicalVisitDate
      this.nextTechnicalVisitDate = data.nextTechnicalVisitDate
      this.dateOfTheNextMaintenanceVisit = data.dateOfTheNextMaintenanceVisit
      this.ivmsTracker = data.ivmsTracker
      this.model = data.model
      this.ivmsReport = data.ivmsReport
      this.killometerOnTheDashboard = data.killometerOnTheDashboard
      this.maintenanceNotebook = data.maintenanceNotebook
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id = data.id
      this.rental =  data.rental
      this.rentalCompany =  data.rentalCompany
      this.rentalCompanyContratReference =  data.rentalCompanyContratReference
      this.company = data.company.id
      this.name = data.name
      this.type = data.type
      this.plateNumber = data.plateNumber
      this.greyCard = data.greyCard
      this.numberOf03PointsSeatBelt = data.numberOf03PointsSeatBelt
      this.numberOf02PointsSeatBelt = data.numberOf02PointsSeatBelt
      this.frontAirbag = data.frontAirbag
      this.firtsReleaseDate = data.firtsReleaseDate
      this.insuranceCompany = data.insuranceCompany.id
      this.typeOfInsuranceContract = data.typeOfInsuranceContract
      this.insurancePolicyNumber = data.insurancePolicyNumber
      this.expiryDate = data.expiryDate
      this.lastTechnicalVisitDate = data.lastTechnicalVisitDate
      this.nextTechnicalVisitDate = data.nextTechnicalVisitDate
      this.dateOfTheNextMaintenanceVisit = data.dateOfTheNextMaintenanceVisit
      this.ivmsTracker = data.ivmsTracker
      this.model = data.model
      this.ivmsReport = data.ivmsReport
      this.killometerOnTheDashboard = data.killometerOnTheDashboard
      this.maintenanceNotebook = data.maintenanceNotebook
      const length = data.vehicleProofs.length
      for (let i = 0; i < length; i++) {
        this.dataImg1.push(data.vehicleProofs[i])
      }
      this.update_Image = true
      this.activePrompt1 = true
    },
    async Delete_Image (data) {

      if (this.id) {
        this.dataImg1_delete.push(data.id)
        this.dataImg1.splice(data.key, 1)
      } else {
        this.dataImg1.splice(data.key, 1)
        this.dataImg1_file.splice(data.key, 1)
        this.AddNewdataImg_file.splice(data.key, 1)
      }
    },

    updateCurrImg1 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.dataImg1_file.unshift(this.$refs.updateImgInput1.files[i])

          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    updateCurrImg2 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.AddNewdataImg_file.unshift(this.$refs.updateImgInput2.files[i])
          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    Show_image (tr) {
      this.images = []
      const image_url = 'https://dev.jra.mitigarisk.com/digiraDev/'
      if (tr.vehicleProofs) {
        const length = tr.vehicleProofs.length
        for (let i = 0; i < length; i++) {
          this.images.push(image_url + tr.vehicleProofs[i].url)
        }
      } else {
        this.images = []
      }
    },
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
   
    openLightbox () {
      this.showLightbox = true
      this.$refs.lightbox.showImage(0)
    },
    intialise () {
      this.id = ''
      if (this.role !== 'managerSubcontractor') {
        this.company = ''
      }
      this.rental = false
      this.company = ''
      this.name = ''
      this.type = ''
      this.plateNumber = ''
      this.greyCard = ''
      this.numberOf03PointsSeatBelt = ''
      this.numberOf02PointsSeatBelt = ''
      this.frontAirbag = 0
      this.firtsReleaseDate = ''
      this.insuranceCompany = ''
      this.typeOfInsuranceContract = ''
      this.insurancePolicyNumber = ''
      this.expiryDate = ''
      this.lastTechnicalVisitDate = ''
      this.nextTechnicalVisitDate = ''
      this.dateOfTheNextMaintenanceVisit = ''
      this.ivmsTracker = 0
      this.model = ''
      this.ivmsReport = 0
      this.killometerOnTheDashboard = ''
      this.maintenanceNotebook = 0
      this.proofs = []
      this.dataImg1 = []
      this.dataImg1_file = []
      this.AddNewdataImg_file = []
      this.dataImg1_delete = []
    },
    
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`vehicles/${id}/`)
            .then(response => {
              this.getAllVehicles()
              window.getPrendTaCom.success('Le véhicule est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
  
    async submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formdata = new FormData()
          this.$vs.loading()
          formdata.append('name', this.name)
          formdata.append('company', this.company)
          formdata.append('rental', this.rental_check)
          formdata.append('rentalCompany', this.rentalCompany)
          formdata.append('rentalCompanyContratReference', this.rentalCompanyContratReference)
          formdata.append('type', this.type)
          formdata.append('plateNumber', this.plateNumber)
          formdata.append('greyCard', this.greyCard)
          formdata.append('numberOf03PointsSeatBelt', this.numberOf03PointsSeatBelt)
          formdata.append('numberOf02PointsSeatBelt', this.numberOf02PointsSeatBelt)
          formdata.append('firtsReleaseDate', this.firtsReleaseDate)
          formdata.append('frontAirbag', this.frontAirbag)
          formdata.append('insuranceCompany', this.insuranceCompany)
          formdata.append('typeOfInsuranceContract', this.typeOfInsuranceContract)
          formdata.append('insurancePolicyNumber', this.insurancePolicyNumber)
          formdata.append('expiryDate', this.expiryDate)
          formdata.append('lastTechnicalVisitDate', this.lastTechnicalVisitDate)
          formdata.append('nextTechnicalVisitDate', this.nextTechnicalVisitDate)
          formdata.append('dateOfTheNextMaintenanceVisit', this.dateOfTheNextMaintenanceVisit)
          formdata.append('ivmsTracker', this.ivmsTracker)
          formdata.append('model', this.model)
          formdata.append('ivmsReport', this.ivmsReport)
          formdata.append('killometerOnTheDashboard', this.killometerOnTheDashboard)
          formdata.append('maintenanceNotebook', this.maintenanceNotebook)
          //Ajout d'image
          if (this.dataImg1_file.length) {
            const length = this.dataImg1_file.length
            for (let i = 0; i < length; i++) {
              formdata.append('proofs', this.dataImg1_file[i],  this.dataImg1_file[i].name)
            }
          }
          //Ajout de nouvelle d'image
          let i = 0
          if (this.AddNewdataImg_file.length) {
            if (this.id) {
              const length = this.AddNewdataImg_file.length
              for (i = 0; i < length; i++) {
                formdata.append('proofs_add', this.AddNewdataImg_file[i], this.AddNewdataImg_file[i].name)
              }
            } else {
              formdata.append('proofs_add', '')
            }
          }
          //suppression d'image

          if (this.dataImg1_delete.length) {
            //const length = this.dataImg1_delete.length
            //for (i = 0; i < length; i++) {
            formdata.append('proofs_delete', this.dataImg1_delete)
            //}
          } else {
            formdata.append('proofs_delete', '')
          }

          let url = 'vehicles/'
          let methods = 'post'
          const message = {
            error: this.$t('save_error'),            
            success: this.$t('vehicle_save')
          }
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
            message.success = this.$t('vehicle_update')

          }

          this.$http[methods](url, formdata)
            .then((response) => {
              window.Vehicles.getAllVehicles()
              this.dataImg1 = []
              window.getPrendTaCom.success(message.success, response)
              this.dataImg1 = []
              this.dataImg1_delete = []
              this.activePrompt1 = false
              this.activePrompt2 = false
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'name') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text: this.$t('name'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'company') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text: this.$t('company'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'type') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('type'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'plateNumber') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('plateNumber'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'greyCard') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('greyCard'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'numberOf03PointsSeatBelt') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('numberOf03PointsSeatBelt'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'numberOf02PointsSeatBelt') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('numberOf02PointsSeatBelt'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'frontAirbag') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('frontAirbag'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'firtsReleaseDate') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('firtsReleaseDate'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'insuranceCompany') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('insuranceCompany'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'typeOfInsuranceContract') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('typeOfInsuranceContract'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'insurancePolicyNumber') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('insurancePolicyNumber'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'expiryDate') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('expiryDate'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'lastTechnicalVisitDate') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('lastTechnicalVisitDate'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'nextTechnicalVisitDate') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('nextTechnicalVisitDate'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'ivmsTracker') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('ivmsTracker'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'model') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('model'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'ivmsReport') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('ivmsReport'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'killometerOnTheDashboard') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('killometerOnTheDashboard'),
                    color: 'warning',
                    position: 'top-left'
                  })
                
                } else if (item === 'maintenanceNotebook') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('maintenanceNotebook'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'proofs') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('proofs'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'rental') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('rental'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'rentalCompany') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('rentalCompany'),
                    color: 'warning',
                    position: 'top-left'
                  })
                } else if (item === 'rentalCompanyContratReference') {
                  this.$vs.notify({
                    time: 6000,
                    title: 'ATTENTION',
                    text:this.$t('rentalCompanyContratReference'),
                    color: 'warning',
                    position: 'top-left'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        }
      })
      
    },

    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
   
    getAllVehicles () {
      this.$vs.loading()
      this.$http.get('activated-vehicle/')
        .then((response) => {
          this.vehicles = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllInsuransces () {
      this.$vs.loading()
      this.$http.get('insuranceCompanies/')
        .then((response) => {
          this.insuransces = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllCompanies () {
      this.$vs.loading()
      this.$http.get('companies/')
        .then((response) => {
          this.companies = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    const user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.role = user_role
    if (this.role === 'managerSubcontractor') {
      this.company = JSON.parse(localStorage.getItem('userInfo')).company.id
    }
    window.Vehicles = this
    this.getAllVehicles()
    this.getAllInsuransces()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style scoped>

</style>
